import React, {useEffect, useState} from 'react';
import './App.css';
import MatchResult from "./components/PDF/MatchResult";
import {useMatch, useAllMembers} from "./graphql";
import {useParams} from "react-router-dom";

export default function Match() {
    const {id} = useParams()
    const [getMatch] = useMatch();
    // const [getAllPlayersClub] = useAllPlayersClub();
    const [MatchData, setMatch] = useState([]);

  

    useEffect(() => {
        
        if (id && id !== "") {
                getMatch({
                    variables: {id: id},
                    fetchPolicy: "network-only",
                    onCompleted: ({getMatch}: any) => {
                        console.log(getMatch)
                        console.log("nami")
                        setMatch(getMatch)
                      
                    }
                })
           
        }
    }, [id])

    return (
        //<div>nami</div>
        <MatchResult matchData={MatchData as any} />
    );
}