import {useLazyQuery, useQuery} from "@apollo/client";
import {League,GetRanking} from "../../"

interface Props {}

export const useLeague = () => {
    return useLazyQuery(League);
};


