import React, { useEffect, useState } from 'react';
import './App.css';
import GoalScorerPDF from "./components/PDF/TopGoalTable";
import { useLeague, useTopGoal } from "./graphql";
import { useParams } from "react-router-dom";

export default function TopGoalTable() {
    const { id } = useParams();
    const [getTopGoal] = useTopGoal();
    const [GoalData, setGoalData] = useState<any>([]);

    useEffect(() => {
        if (id && id !== "") {
            console.log(id);
            getTopGoal({
                variables: { leagueId: id },
                fetchPolicy: "network-only",
                onCompleted: ({ calculateGoalPlayer }: any) => {
                    console.log("nami")
                    console.log(calculateGoalPlayer)
                    setGoalData(calculateGoalPlayer)
                    
                },
            });
        }
    }, [id]);

   
    return (
        <div>
            <GoalScorerPDF goalData={GoalData as any} />
        </div>
    );
}
