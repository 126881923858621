import React, { useEffect, useState } from 'react';
import './App.css';
import LeaguePDF from "./components/PDF/League";
import GoalScorerPDF from "./components/PDF/TopGoalTable";
import { useLeague, useGetRanking, useTopGoal } from "./graphql";
import { useParams } from "react-router-dom";

export default function League() {
    const { id } = useParams();
    const [getLeague] = useLeague();
    const [getRanking] = useGetRanking();
    const [getTopGoal] = useTopGoal();
    const [RankingData, setRankingData] = useState<any>([]);
    const [GoalData, setGoalData] = useState<any>([]);

    useEffect(() => {
        if (id && id !== "") {
            console.log(id);
            getLeague({
                variables: { id: id },
                fetchPolicy: "network-only",
                onCompleted: ({ league }: any) => {
                    let groupedData = new Map();
                    
                    for (let i = 0; i < league?.participatingTeams?.length; i++) {
                        const item = league?.participatingTeams?.[i];

                        if (groupedData.has(item.group)) {
                            groupedData.get(item.group).push(item);
                        } else {
                            groupedData.set(item.group, [item]);
                        }
                    }

                    const groupedArray = Array.from(groupedData.values()).sort((a, b) => {
                        return a[0].group.localeCompare(b[0].group);
                    });

                    getRanking({
                        variables: { leagueId: id },
                        fetchPolicy: "network-only",
                        onCompleted: ({ calculatePoints }: any) => {
                            console.log("****")
                            console.log(calculatePoints)
                            console.log("****")
                            const mergedData = groupedArray.map((group: any) => {
                                return group.map((teamData: any) => {
                                    const teamStats = calculatePoints.find((tp: any) => tp.team.id === teamData.team.id);
                                    const points = teamStats ? teamStats.points : 0;
                                    const matchesPlayed = teamStats ? teamStats.matchesPlayed : 0;
                                    const wins = teamStats ? teamStats.wins : 0;
                                    const draws = teamStats ? teamStats.draws : 0;
                                    const losses = teamStats ? teamStats.losses : 0;
                    
                                    return { ...teamData, points, matchesPlayed, wins, draws, losses };
                                }).sort((a: any, b: any) => b.points - a.points);
                            });
                            console.log(mergedData)
                            setRankingData(mergedData);
                        },
                        onError: () => {
                            console.log("error");
                        },});

                    getTopGoal({
                        variables: { leagueId: id },
                        fetchPolicy: "network-only",
                        onCompleted: ({ calculateGoalPlayer }: any) => {
                            setGoalData(calculateGoalPlayer);
                        },
                        onError: () => {
                            console.log("error");
                        },
                    });
                },
                onError: () => {
                    console.log("error");
                },
            });
        }
    }, [id]);

    return (
        <div>
            <LeaguePDF rankingData={RankingData as any} goalData={GoalData as any}/>
        </div>
    );
}