import React, { useState } from "react";
import { Page, Text, Image, Document, StyleSheet, View, Font, PDFViewer } from "@react-pdf/renderer";

interface Props {
    goalData?: any;
}

Font.register({
    family: "Montserrat-Arabic",
    fonts: [
        {
            src: "/fonts/Montserrat-Arabic-Regular.ttf",
            fontStyle: "normal",
            fontWeight: 400,
        },
        {
            src: "/fonts/Montserrat-Arabic-Medium.ttf",
            fontStyle: "normal",
            fontWeight: 700,
        },
    ]
});

const styles = StyleSheet.create({
    body: {
        fontFamily: "Montserrat-Arabic",
        backgroundColor: "#fff",
        fontSize: 12,
        padding: 14,
        direction: "rtl",
    },
    smFont: {
        fontSize: 9,
        color: "#888",
    },
    horizontalDivider: {
        position: "absolute",
        top: 0,
        left: "50%",
        height: "100%",
        textAlign: "center",
        borderLeft: `2px dashed #333`,
    },
    verticalDivider: {
        position: "absolute",
        top: "50%",
        left: 0,
        width: "100%",
        textAlign: "center",
        borderTop: `2px dashed #333`,
    },
});

const GoalScorerPDF = ({ goalData }: Props) => {
    return (
        <PDFViewer style={{ minHeight: "calc(100vh - 25px )", minWidth: "calc(100vw - 10px )" }}>
            <Document>
                <Page orientation={"portrait"} style={styles.body} size={"A4"}>
                    <View style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", alignItems: "center", border: "1px solid #555", justifyContent: "flex-start", padding: '5px' }}>
                        <View style={{ display: "flex", flexDirection: "row", width: "100%", height: "3cm", alignItems: "center", justifyContent: "space-between" }}>
                            {/* section 1 */}
                            <View style={{ flex: 2.5 }}>
                                <View style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    {/* <Image
                                        style={{ width: "20mm", height: "20mm" }}
                                        src={qrCodeGenerator("312456456")}
                                    /> */}
                                </View>
                            </View>

                            {/* section 2 */}
                            <View style={{ flex: 1 }}>
                                <View style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "18mm", width: "18mm" }}>
                                    {/* logo */}
                                    <Image style={{ width: "20mm", height: "20mm" }} src={"/logo.jpg"} />
                                </View>
                            </View>
                        </View>

                        <Text style={{ fontSize: 16, fontWeight: 700, margin: "10px 0", textAlign: "right", marginBottom: "20px" }}>هدافي الدوري</Text>

                        <View style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "space-between", padding: "0cm 0.2cm" }}>
                            <View style={{ flex: 1, border: "1px solid #555", height: "1cm", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#eee" }}>
                                <Text style={{ fontSize: 9, fontWeight: 500 }}>الهدف</Text>
                            </View>
                            <View style={{ flex: 2, border: "1px solid #555", height: "1cm", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#eee" }}>
                                <Text style={{ fontSize: 9, fontWeight: 500 }}>اسم اللاعب</Text>
                            </View>
                            <View style={{ flex: 2, border: "1px solid #555", height: "1cm", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#eee" }}>
                                <Text style={{ fontSize: 9, fontWeight: 500 }}>الفريق</Text>
                            </View>
                            <View style={{ flex: 0.5, border: "1px solid #555", height: "1cm", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#eee" }}>
                                <Text style={{ fontSize: 9, fontWeight: 500 }}>#</Text>
                            </View>
                        </View>

                        {goalData?.map((playerData: any, playerIndex: number) => (
                            <View key={playerIndex} style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "space-between", padding: "0.2cm 0.2cm 0" }}>
                                <View style={{ flex: 1, border: "1px solid #555", height: "1cm", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Text style={{ fontSize: 9, fontWeight: 400 }}>
                                        {playerData.Goal}
                                    </Text>
                                </View>
                                <View style={{ flex: 2, border: "1px solid #555", height: "1cm", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Text style={{ fontSize: 9, fontWeight: 400 }}>
                                        {`${playerData.PlayerID.player.person.first_name} ${playerData.PlayerID.player.person.second_name} ${playerData.PlayerID.player.person.third_name}`}
                                    </Text>
                                </View>
                                <View style={{ flex: 2, border: "1px solid #555", height: "1cm", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Text style={{ fontSize: 9, fontWeight: 400 }}>
                                        {playerData.team}
                                    </Text>
                                </View>
                                <View style={{ flex: 0.5, border: "1px solid #555", height: "1cm", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Text style={{ fontSize: 9, fontWeight: 400 }}>
                                        {playerIndex + 1}
                                    </Text>
                                </View>
                            </View>
                        ))}
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default GoalScorerPDF;