import React, { useEffect, useState } from 'react';
import './App.css';
import AssemblyCardTemplate from "./components/PDF/AssemblyCard";
import { useAssembly } from "./graphql";
import { useParams } from "react-router-dom";

function CardAssembly() {
    const { id } = useParams();
    const [getAssembly, { data: dataAssembly, loading }] = useAssembly();
    const [assembly, setAssembly] = useState(null);

    useEffect(() => {
        if (id && id !== "") {
            getAssembly({
                variables: { id },
                fetchPolicy: "network-only"
            });
        }
    }, [id, getAssembly]);

    useEffect(() => {
        if (dataAssembly) {
            setAssembly(dataAssembly.assembly);
        }
    }, [dataAssembly]);

    if (loading || !assembly) {
        return <div>Loading...</div>; // Show loading indicator while fetching data
    }

    return (
        <AssemblyCardTemplate assembly={assembly} />
    );
}

export default CardAssembly;
