import React, {useEffect, useState} from 'react';
import './App.css';
import ListTechnicals from "./components/PDF/ListTechnicals";
import ListParticipantPlayer from "./components/PDF/ListParticipantPlayer";
import {useAllParticipatingPlayers, useAllParticipatingTechnicalStaff} from "./graphql";
import {useParams} from "react-router-dom";
import dayjs from 'dayjs';

export default function Participating() {
    const {id,  className} = useParams()
    const [getAllParticipatingTechnicalStaff] = useAllParticipatingTechnicalStaff();
    const [getAllParticipatingPlayers] = useAllParticipatingPlayers();
    const [allPlayers, setAllPlayers] = useState([]);

    useEffect(() => {
        if (id && id !== "") {
            console.log(id)
            console.log(className)
          
            if( className ==='technicalstaff'){
                getAllParticipatingTechnicalStaff({
                    variables: {idParticipatingTeams: id},
                    fetchPolicy: "network-only",
                    onCompleted: ({allParticipatingTechnicalStaff}) => {
                        console.log("*****staff*******")
                        setAllPlayers(allParticipatingTechnicalStaff)
                        console.log(allParticipatingTechnicalStaff)
                    }
                })
                
            }
            else{
                getAllParticipatingPlayers({
                    variables: {idParticipatingTeams: id},
                    fetchPolicy: "network-only",
                    onCompleted: ({allParticipatingPlayers}) => {
                        console.log("*****player*******")
                        setAllPlayers(allParticipatingPlayers)
                        console.log(allParticipatingPlayers)
                    }
                })
            }
        }
        
    }, [id])

    useEffect(() => {
       
      
    }, [allPlayers]);
    if( className ==='technicalstaff'){
        return (
            <ListTechnicals players={allPlayers as any} />
        );
    }
    else{
        return (
            <ListParticipantPlayer players={allPlayers as any} />
        );
    }
    
}