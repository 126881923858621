import {gql} from "@apollo/client";

export const Match = gql`
    query Match($id: ID) {
        getMatch(id: $id) {
            
            id
                date
                firstTeamGoal
                secondTeamGoal
                type

                manOfMatch
                arbitre{
                  id
                	Arbitre1
                  Arbitre2
                  Arbitre3
                  Arbitre4
                }
    
                firstTeam {
                    id
                    participatingPlayers{
                          id 
                          number
                          participatingPlayersMatches{
                            id
                            starter
                            sub
                            id_match{
                                id
                                }
                            }
                          player{
                            id
                            person{
                              first_name
                              second_name

                            }
                          }
                        }
                    team {
                        id
                        name
                    
                    }
                }
                secondTeam {
                    id
                  participatingPlayers{
                          id 
                          number
                          participatingPlayersMatches{
                            id
                            starter
                            sub
                            id_match{
                                id
                                }
                            }
                          player{
                            id
                            person{
                              first_name
                              second_name

                            }
                          }
                        }
                    team {
                        id
                        name
                    }
                }

                firstTeamCards {
                    id
                    type
                    player
                    date
                    team {
                        id
                        team {
                            id
                            name
                        }
                    }
                }
                
                secondTeamCards {
                    id
                    type
                    player
                    date
                    team {
                        id
                        team {
                            id
                            name
                        }
                    }
                }

                firstTeamScorersMatch {
                    id
                    time
                    participating_team {
                        id
                        team {
                            id
                            name
                        }
                    }
                    participating_player {
                        id
                        number

                        player {
                            id
                            player_center
                            person {
                                first_name
                                second_name
                                third_name
                                tribe
                            }
                        }
                    }
                }

                secondTeamScorersMatch {
                    id
                    time
                    participating_team {
                        id
                        team {
                            id
                            name
                        }
                    }
                    participating_player {
                        id
                        number

                        player {
                            id
                            player_center
                            person {
                                first_name
                                second_name
                                third_name
                                tribe
                            }
                        }
                    }
                }

                createdAt
                updatedAt
        }
    }
`;