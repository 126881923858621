import React, { useEffect } from 'react';
import './App.css';
import CardTemplate from "./components/PDF/Card";
import { usePlayer } from "./graphql";
import { useParams } from "react-router-dom";

function App() {
  const { id } = useParams();
  const [getPlayer, { data: dataPlayer, loading }] = usePlayer();

  useEffect(() => {
    if (id && id !== "") {
      getPlayer({
        variables: { id },
        fetchPolicy: "network-only"
      });
    }
  }, [id]);

  if (loading || !dataPlayer) {
    return <div>Loading...</div>;
  }

  return (
    <CardTemplate player={dataPlayer.player} />
  );
}

export default App;
